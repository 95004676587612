<template>
    <div class="cont">

        <div class="seleLangs">
            <div class="imgList">
                <a :href="kefu_link" class="list-group-item list-group-item-action border-color" style="float: left;margin-right: 10px;">
                    <div class="row">
                        <div class="col align-self-center pl-0"><img src="../assets/ke.png" alt=""></div>
                    </div>
                </a>
                <a href="/index/user/lang.html" class="list-group-item list-group-item-action border-color" style="float: left">
                    <div class="row">
                        <div class="col align-self-center pl-0"><img src="../assets/yan.png" alt=""></div>
                    </div>
                </a>
            </div>
        </div>
		<div class="logo">
			<img src="../assets/logo.png"/>
		</div>
        <div class="inputs">
			
            <div class="tabs">
                <div class="phone">
                    <img src="../assets/us.png" alt="">
                    <input type="text" name="tel" @input="inname" placeholder-color="red" :placeholder="please_enter" />
                </div>
                <div class="phone password">
                    <img src="../assets/pas.png" alt="">
                    <input type="password" name="pwd" @input="inpass" :placeholder="your_password" />
                </div>
                <div class="sele">
                    <ul>
                        <li>
                            <img class="agrees" src="../assets/agree.png" alt="" />
                        </li>
                    </ul>
                </div>
                <div class="loginBtn" @click="login"></div>
            </div>
            <div class="tips"><i><a style="color: #f24255;"  @click="register">{{reg_title}}</a></i></div>
        </div>
        
    </div>
</template>

<script>
export default {
    name: "Login",
    props: {
        msg: String,
    },
    data() {
        return {
            user: {
                tel: "",
                pwd: "",
            },
            reme: false,
            
            please_enter: "",
            your_password: "",
            login_1: "",
            no_account: "",
            reg_title: "",
            kefu_link: "",
        };
    },
    created() {
        // 初始化操作，如获取数据
        this.fetchData();
    },
    methods: {
        async fetchData() {
            var result = await this.$proxy.getvuelang();
            // console.log(result);
            var info = result.info;
            this.please_enter = info.please_enter;
            this.your_password = info.your_password;
            this.login_1 = info.login_1;
            this.no_account = info.no_account;
            this.reg_title = info.reg_title;
            this.kefu_link = info.kefu_link;

            if(typeof this.please_enter == "undefined"){
                this.please_enter = 'Please enter the platform account';
                this.your_password = 'your password';
                this.login_1 = 'login';
                this.no_account = 'No account?';
                this.reg_title = 'Register';
            }
        },
        // 获取用户名和密码
        inname(e) {
            this.user.tel = e.target.value;
        },
        inpass(e) {
            this.user.pwd = e.target.value;
        },
        // 跳转到注册
        register() {
            this.$router.push({ path: "/register" });
        },
        // 跳转到语言
        lang() {
            window.open('/index/user/lang','_self');
        },
        // 登录
        async login() {
            if (this.user.tel === "" || this.user.pwd === "") {
                this.$vs.notification({
                    position: "top-center",
                    icon: '<span class="material-icons">error</span>',
                    color: "warn",
                    // title: "错误",
                    text: "Please enter account / password",
                });
                return false;
            }
            // 登录接口
            var result = await this.$proxy.login(this.user);
            // console.log(result)
            if (result.code === 0) {
                //存入token
                // localStorage.setItem('token',result.data['token']);
                // alert(localStorage.token);
                const loading = this.$vs.loading({
                    type: "circles",
                    color: "#d5397b",
                    text: "Login success",
                });
                this.hasOpenLoading = true;
                setTimeout(() => {
                    loading.close();
                    this.hasOpenLoading = false;
                }, 2000);
				sessionStorage.setItem('login_flag', '1');
                window.open('/index/index/home','_self');
                // this.$router.replace("/index/index/home");
            } else {
                this.$vs.notification({
                    position: "top-center",
                    icon: '<span class="material-icons">error</span>',
                    color: "warn",
                    // title: "错误",
                    text: result.info,
                });
            }
        },
    },
};
</script>


<style scoped>

        .imgList {
            position: absolute;
            right: 17px;
            z-index: 999;
            /* background: #fff; */
            top: 20px;
        }

        .imgList ul li {
            margin-bottom: 5px;
            border-radius: 5px;
            overflow: hidden;
            width: 45px;
            height: 30px;
        }

        .imgList ul li img {
            display: inline-block;
            width: 45px;
            height: 30px;
        }

        .imgLists {
            position: absolute;
            right: 17px;
            z-index: 999;
            /* background: #fff; */
            top: 20px;
            display: none;
        }

        .seleLangs {
            position: fixed;
            width: 100%;
            right: 7px;
            z-index: 999;
            top: 10px;
        }

        .cont {
            display: inline-block;

            width: 100%;
            height: 100vh;

            position: relative;

            background: url(../assets/logonbgsss.jpg) no-repeat;

            background-size: 100% 100%;
        }

        .inputs {
            margin-top: 150px;
            border-radius: 50px;
            background-color: #fff;

            padding: 25px 35px 25px;
            box-sizing: border-box;
            position: fixed;
            width: 100%;
            border-radius: 25px 25px 0 0;
            bottom: 0;
            height: 65%;
            background: #83e3fb;
            margin-top: 64px;

        }

        .name {
            font-size: 14px;
            color: #000;
            font-weight: 700;
        }

        input {
            background: none;
            border: none;
            width: 100%;
            height: 100%;

        }

        .password {
            margin-top: 30px !important;
        }

        .sele ul {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 12px;
            height: 30px;
            line-height: 30px;
        }

        .sele ul li {
            display: flex;
            align-items: center;
        }

        .loginBtn {
            color: #fff;
            width: 92px;
            height: 95px;
            text-align: center;
            border-radius: 5px;
            background: url(../assets/loginBtns.jpg) no-repeat;
            border-radius: 50%;
            overflow: hidden;
            margin: 0 auto;
        }

        .cont .tips {
            position: fixed;
            bottom: 20%;
            width: 100%;
            text-align: center;
            font-size: 14px;
            left: 30%;

        }

        .cont .tips i {
            font-style: inherit;
            color: rgb(101, 168, 247);
        }

        .noAgree, .agrees {
            display: inline-block;
            width: 18px;
            height: 18px;
            margin-right: 10px;
        }

        .agrees {
            display: none;
        }

        .imgList img {
            display: flex;
            width: 25px;
            height: 25px;
        }

        input {
            font-size: 16px;
            color: fieldtext !important;
        }

        input::-webkit-input-placeholder {
            color: #fff;
            font-size: 18px;
            opacity: 0.8;
        }

        input::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            color: #fff;
            font-size: 18px;
            opacity: 0.8;
        }

        input:-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            color: #fff;
            font-size: 18px;
            opacity: 0.8;
        }

        input:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #fff;
            font-size: 18px;
            opacity: 0.8;
        }

        .phone {
            border: 1px solid #ffff;
            height: 55px;
            border-radius: 5px;
            padding-left: 15px;
            display: flex;
            align-items: center;
            margin-top: 64px;
        }

        .phone img {
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-right: 10px;
        }

        .tipsVal {
            padding-top: 100px;
        }

        .tipsVal1 {
            font-size: 30px;
            color: #ffff;
        }

        .tipsVal2 {
            font-size: 20px;
            color: #ffff;
            margin-top: 10px;
        }
        .list-group-item {
            background-color:transparent;
            border: none;
        }
        .list-group-item:hover {
            background-color:transparent;
            border: none;
        }
		.logo{
			text-align: center;
			position: absolute;
			top: 18%;
			width: 100%;
		}
</style>
