<template>
    <div class="wrapper passport conts eee">
        <div class="header">
            <div class="row no-gutters">
                <div class="col-2"></div>
                <div class="col-8 text-center"><span class="btn small" style="width: 100%; display: none">registered
                        account</span></div>
                <div class="col-2 text-right"><a href="/index/user/lang" class="btn btn-link text-dark"><i
                            class="material-icons" style="display: inline-block;margin-right: 10px;">language</i></a>
                </div>
            </div>
        </div>
        <div class="container" style="background: #fff; opacity: 0.8">
            <div class="bg-default">
                <div class="row no-gutters login-row">
                    <div class="col align-self-center px-3 text-center mt-3">
                        <p class="mt-2" style="height:30px"><b></b></p>
                        <form class="form-signin">
                            <!-- <div class="form-group">
                                <div class="names"><span>*</span>user name</div><input type="text" name="user_name"
                                    class="form-control form-control-lg inputs text-center"
                                    placeholder="Please enter a user name" value="" required="" autofocus="" />
                            </div> -->
                            <div class="names"><span>*</span>{{platform_account}}</div>
                            <div class="form-group input-group" style="display: flex">
                            <input type="text" id="phone" name="tel" v-model="user.tel" :placeholder="please_set" 
                                    class="form-control" style='flex:3' aria-label="Recipient's username" />
                            </div>

                            <div class="form-group">
                                <div class="names"><span>*</span>{{r_password}}</div>
                                <input type="password" name="pwd" v-model="user.pwd" 
                                    class="form-control form-control-lg text-center" :placeholder="your_password" 
                                    value="" required="" autofocus="" />
                            </div>

                            <div class="form-group">
                                <div class="names"><span>*</span>{{qu_newpass}}</div><input type="password"
                                    name="deposit_pwd" class="form-control form-control-lg text-center" 
                                    :placeholder="login_pass2" v-model="user.deposit_pwd" value="" required="" autofocus="" />
                            </div>

                            <div class="form-group">
                                <div class="names"><span>*</span>{{payment_password}}</div>
                                <input type="password" name="self_pwd" class="form-control form-control-lg text-center" 
                                    :placeholder="enter_password" v-model="user.self_pwd" value="" required="" autofocus="" />
                            </div>

                            <div class="names"><span>*</span>{{extension_code}}</div>
                            <input type="text" name="invite_code" class="form-control form-control-lg text-center" 
                                :placeholder="login_qr" v-model="user.invite_code" value="" required="" autofocus="" />

                            <input type="hidden" name="_csrf_" value="" />

                        </form>
                    </div>
                </div>
                <div class="row mx-0 mt-4" style="margin: 0 auto;">
                    <div class="btnsss">
                        <a class="btn btn-default btn-lg btn-rounded shadow btn-block form-buttom" @click="regit">{{registered_account}}</a>
                    </div>
                </div>
                <div class="row mx-0 text-center justify-content-center" style="height:4rem;padding-top: 1rem;font-size:14px;">
                    <a @click="goLogin" class="mt-4 d-block">{{login_1}}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Register",
    props: {
        msg: String,
    },
    data() {
        return {
            user: {
                user_name: "",
                tel: "",
                pwd: "",
                deposit_pwd: "",
                self_pwd: "",
                invite_code: "",
            },
            user_name: "",
            platform_account: "",
            r_password: "",
            qu_newpass: "",
            payment_password: "",
            extension_code: "",
            registered_account: "",
            login_1: "",
            enter_name: "",
            please_set: "",
            your_password: "",
            login_pass2: "",
            enter_password: "",
            login_qr: "",
        };
    },
    created() {
        // 初始化操作，如获取数据
        this.fetchData();
    },
    methods: {
        async fetchData() {
            var result = await this.$proxy.getvuelang();
            // result = JSON.parse(result);
            var info = result.info;
            this.user_name = info.user_name;
            this.platform_account = info.platform_account;
            this.r_password = info.r_password;
            this.qu_newpass = info.qu_newpass;
            this.payment_password = info.payment_password;
            this.extension_code = info.extension_code;
            this.registered_account = info.registered_account;
            this.login_1 = info.login_1;

            this.enter_name = info.enter_name;
            this.please_set = info.please_set;
            this.your_password = info.your_password;
            this.login_pass2 = info.login_pass2;
            this.enter_password = info.enter_password;
            this.login_qr = info.login_qr;

            if(typeof this.user_name == "undefined"){
                this.user_name = 'username';
                this.platform_account = 'Platform account';
                this.r_password = 'password';
                this.qu_newpass = 'Confirm password';
                this.payment_password = 'modify payment password';
                this.extension_code = 'invitation code';
                this.registered_account = 'registered account';
                this.login_1 = 'login';

                this.enter_name = 'Please enter a username';
                this.please_set = 'Please set your platform login account';
                this.your_password = 'your password';
                this.login_pass2 = 'Please confirm the password';
                this.enter_password = 'Please enter your payment password';
                this.login_qr = 'Please enter the invitation code';
            }
        },
      // 跳转到语言
        lang() {
            window.open('/index/user/lang.html','_self');
        },
        // 注册用户
        async regit() {
            if (this.user.tel == "" || this.user.pwd == "" || this.user.pwd !== this.user.deposit_pwd) {
                this.$vs.notification({
                    position: "top-center",
                    icon: '<span class="material-icons">error</span>',
                    color: "danger",
                    title: "error",
                    text: "The two passwords are inconsistent",
                });
                return false;
            }

            if(this.user.invite_code.trim().length <= 0){
                this.$vs.notification({
                    position: "top-center",
                    icon: '<span class="material-icons">error</span>',
                    color: "danger",
                    title: "error",
                    text: this.login_qr,
                });
                return false;
            }

            // var re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,13}$/ // 密码6~13位 必须包含大小写字母和数字的组合
            // var pass = re.test(this.user.passwd);
            // if(!pass){
            //   this.$vs.notification({
            //     position:'top-center',
            //     icon:'<span class="material-icons">error</span>',
            //     color:'warn',
            //     title: '错误',
            //     text: `密码6~13位 必须包含大小写字母和数字的组合哦`
            //   })
            //   return false
            // }

            // 注册接口
            var result = await this.$proxy.regdit(this.user);
            // console.log(result);
            if (result.code == 1) {
                this.$vs.notification({
                    position: "top-center",
                    icon: '<span class="material-icons">error</span>',
                    color: "warn",
                    title: "error",
                    text: result.info,
                });
                return false;
            } else if (result.code == 0) {
                this.$vs.notification({
                    position: "top-center",
                    icon: '<span class="material-icons">check</span>',
                    color: "success",
                    title: "success",
                    text: "Register success",
                });
                window.open('/index/user/agreement','_self');
            }
        },

        // 去登录
        goLogin() {
            this.$router.push("/login");
        },
    },
};
</script>

<style scoped>
        .show-tick {
            flex: 0.4 !;
        }

        .conts {
            display: inline-block;

            width: 100%;
            height: 100vh;

            position: relative;

            background: url(../assets/logonbgsss.jpg) no-repeat;

            background-size: 100% 100%;
        }

        .form-signin input {
            text-align: left !important;
            display: flex;
            box-sizing: border-box;
            flex-direction: row;
            align-items: center;
            border: 1px solid #dcdfe6;
            border-radius: 4px;
            height: 36px;
            font-size: 14px;
        }

        .names {
            text-align: left;
            margin-bottom: 8px;
            font-size: 12px;
        }

        .names span {
            color: red;
            font-size: 16px;
        }

        .btnsss {
            width: 100%;
        }

        .btnsss a {
            background: #4ecbfe;
            border-radius: 28px;
            color: #fff;
        }

.col-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}
.text-center {
    text-align: center !important;
}
.no-gutters>.col, .no-gutters>[class*=col-] {
    padding-right: 0;
    padding-left: 0;
}
.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
}

.text-center {
    text-align: center !important;
}

.pl-3, .px-3 {
    padding-left: 1rem !important;
}
.pr-3, .px-3 {
    padding-right: 1rem !important;
}
.mt-3, .my-3 {
    margin-top: 1rem !important;
}
.align-self-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
}
.mt-4, .my-4 {
    margin-top: 1.5rem !important;
}
.btn-lg, .btn-group-lg>.btn {
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.33;
    border-radius: 6px;
}

</style>
